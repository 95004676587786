import axiosInstance from './../../axiosInstance';
import { types } from './types';

export function updateURL(history) {
  history.push('/dashboard');
}

export function redirectUserToLogin(history) {
  history.push('/login');
}

export function authenticateUser(data, history) {
  console.log(data);
  return (dispatch) => {
    dispatch({ type: types.USER_LOGIN_REQUEST });
    axiosInstance({ method: 'post', url: `/users/sign_in`, data: { ...data } })
      .then((response) => {
        console.log(response);
        localStorage.setItem(
          'authentication_token',
          response.data.authentication_token,
        );
        dispatch({ type: types.USER_LOGIN_SUCCESS, data: response.data });
        history.push('/event-code');
      })
      .catch((error) => {
        dispatch({ type: types.USER_LOGIN_FAILURE, error });
        if (error.response.status !== 200) {
          if (error.response.data.message === 'Invalid credentials') {
          }
          if (
            error.response &&
            error.response.data.message === 'Signature has expired'
          ) {
            localStorage.removeItem('authentication_token');
          }
        }
      });
  };
}

export function updateEventCode(data, history) {
  console.log(data);
  return (dispatch) => {
    localStorage.setItem('eventCode', data.eventCode);
    dispatch({ type: types.UPDATE_EVENT_CODE, data });
    history.push('/leaderboard');
  };
}

export function logoutUser(history) {
  return (dispatch) => {
    //dispatch({ type: types.GET_BUSINESS_TYPES_REQUEST });
    /*need handle request loader case*/
    axiosInstance({
      method: 'post',
      url: `/logout/user`,
      headers: {
        Authorization: localStorage.getItem('auth_token'),
      },
    })
      .then((response) => {
        dispatch({ type: types.USER_LOGOUT_SUCCESS });
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');
        localStorage.removeItem('organizationId');
        history.push('/login');
      })
      /*need handle failure case*/
      .catch((error) => {
        if (
          error.response &&
          error.response.data.message === 'Signature has expired'
        ) {
          localStorage.removeItem('auth_token');
          localStorage.removeItem('user');
          localStorage.removeItem('organizationId');
          history.push('/login');
        }
      });
  };
}
