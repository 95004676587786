import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//import { loadUserData } from './data/auth/actions';
// import LoginContainer from './data/auth/LoginContainer';
// import EventCodeDetails from './data/auth/EventCodeDetails';
import LeaderBoard from './data/leaderboard/LeaderBoard';

class App extends Component {
  componentWillMount = () => {
    // const { loadUserData } = this.props;
    // loadUserData();
  };
  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Switch>
              <Route path="/" component={LeaderBoard} exact strict />
              {/* <Route path="/login" component={LoginContainer} exact strict />
              <Route
                path="/event-code"
                component={EventCodeDetails}
                exact
                strict
              /> */}
              <Route
                path="/leader-board"
                component={LeaderBoard}
                exact
                strict
              />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // loadUserData: () => {
    //   dispatch(loadUserData());
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
