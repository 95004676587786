import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { authReducer, sharedReducer, leaderBoardReducer } from './data';

const appReducer = combineReducers({
  authReducer,
  sharedReducer,
  leaderBoardReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT_SUCCESS') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
