import axiosInstance from '../../axiosInstance';
import { types } from './types';

export function getLeaderBoard() {
  return (dispatch) => {
    dispatch({ type: types.GET_LEADER_BOARD_REQUEST });
    axiosInstance({
      method: 'get',
      url: `/hole_info/leader_board`,
    })
      .then((response) => {
        dispatch({ type: types.GET_LEADER_BOARD_SUCCESS, data: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_LEADER_BOARD_FAILURE, error });
        // if (error.response.status !== 200) {
        //   if (error.response.data.message === 'Invalid credentials') {
        //   }
        //   if (
        //     error.response &&
        //     error.response.data.message === 'Signature has expired'
        //   ) {
        //     localStorage.removeItem('auth_token');
        //     localStorage.removeItem('user');
        //     localStorage.removeItem('organizationId');
        //   }
        // }
      });
  };
}
