import axios from 'axios';

const axiosInstance = axios.create({
  //baseURL: 'https://www.winningticket.com/api/v1',
});

if (process.env.NODE_ENV === 'production') {
  axiosInstance.defaults.baseURL = 'https://www.winningticket.com/api/v1';
} else if (process.env.NODE_ENV === 'staging') {
  axiosInstance.defaults.baseURL = 'https://staging.winningticket.com/api/v1';
} else {
  axiosInstance.defaults.baseURL = 'https://staging.winningticket.com/api/v1';
}

axiosInstance.defaults.headers.common['auth-token'] = 'React';

export default axiosInstance;
