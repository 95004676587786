import { types } from './types';

const initialState = {
  shamble_leader_board: [],
  stroke_leader_board: [],
};
export default function leaderBoardReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LEADER_BOARD_REQUEST: {
      return { ...state };
    }
    case types.GET_LEADER_BOARD_SUCCESS: {
      return {
        shamble_leader_board: action.data.shamble_leader_board,
        stroke_leader_board: action.data.stroke_leader_board,
      };
    }
    case types.GET_LEADER_BOARD_FAILURE: {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
