import React from 'react';

function TableRow(props) {
  const { team_name, thru, scoreKey, parKey, positionKey } = props;
  return (
    <div className="table-row">
      <div className="table-cell">{props[positionKey]}</div>
      <div className="table-cell">
        {team_name.length > 70 ? team_name.substr(0, 68) + '...' : team_name}
      </div>
      <div className="table-cell">{thru || 0}</div>
      {/* <div className="table-cell">{props[scoreKey] || 0}</div> */}
      <div className="table-cell">{!props[parKey] ? 'E' : props[parKey]}</div>
    </div>
  );
}

export default TableRow;
