import { types } from './types';

const initialState = {
  closeSidebar: false,
  organizationDetails: {},
  // isFetching: false,
};
export default function sharedReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR: {
      return { ...state, closeSidebar: !state.closeSidebar };
    }
    case types.GET_ORGANIZATION_DETAILS_REQUEST: {
      return { ...state };
    }
    case types.GET_ORGANIZATION_DETAILS_SUCCESS: {
      return { ...state, organizationDetails: action.data };
    }
    case types.GET_ORGANIZATION_DETAILS_FAILURE: {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
