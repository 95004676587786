import React from 'react';

function Card(props) {
  const { team_name, thru, scoreKey, parKey, positionKey } = props;
  console.log(props[parKey]);
  return (
    <div className="card">
      <div className="position">{props[positionKey]}</div>
      <div className="name">
        {team_name.length > 26 ? team_name.substr(0, 23) + '...' : team_name}
      </div>
      <div className="details">
        <div className="item">
          <div className="title">thru</div>
          <div className="value">{thru || 0}</div>
        </div>
        {/* <div className="item">
          <div className="title">score</div>
          <div className="value">{props[scoreKey] || 0}</div>
        </div> */}
        <div className="item">
          <div className="title">to par</div>
          <div className="value">{!props[parKey] ? 'E' : props[parKey]}</div>
        </div>
      </div>
    </div>
  );
}

export default Card;
