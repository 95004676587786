import React, { Component } from 'react';
import classNames from 'classnames';
import { split } from 'lodash';
//import eyeImage from './../../images/password-eye.png';

class Input extends Component {
  state = {
    inputFilled: false,
    inputValue: this.props.value || '',
    showErrorMessage: false,
    inputType: this.props.type,
    inputErrorMessage: this.props.blankError || 'Please fill',
  };
  onEnterInput = (e) => {
    const { getValue } = this.props;
    getValue(e.target.value);
    this.setState({
      inputValue: e.target.value,
      showErrorMessage: false,
      inputFilled: true,
    });
  };
  validateInput = (type, inputValue, name) => {
    if (type === 'email') {
      let regX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regX.test(inputValue)) {
        return true;
      }
      return false;
    } else if (type === 'text' && name === 'fullName') {
      if (
        split(inputValue, ' ').length === 1 ||
        split(inputValue, ' ')[1] === ''
      ) {
        return false;
      }
      return true;
    } else if (type === 'text' && name === 'mobileNumber') {
      let regX = new RegExp('^([0|+[0-9]{1,5})?([7-9][0-9]{9})$');
      if (regX.test(parseInt(inputValue))) {
        return true;
      }
      return false;
    } else if (type === 'text' && name === 'number') {
      if (isNaN(inputValue)) {
        return false;
      }
      return true;
    }
    return true;
  };
  onBlurInput = (e) => {
    const { getValue, required, type, name, blankError } = this.props;
    if (e.target.value) {
      this.setState({ inputFilled: true });
      // this.setState({ inputErrorMessage: errorMessage });
      if (required) {
        let isError = this.validateInput(type, e.target.value, name);
        this.setState({ showErrorMessage: !isError });
        if (isError) {
          getValue(e.target.value);
        } else {
          getValue('');
        }
      } else {
        getValue(e.target.value);
      }
    } else {
      if (typeof blankError === 'undefined') {
        this.setState({
          inputFilled: false,
          showErrorMessage: true,
          inputErrorMessage: 'Please Fill',
        });
        getValue('');
      } else {
        this.setState({
          inputFilled: false,
          showErrorMessage: true,
          inputErrorMessage: blankError,
        });
        getValue('');
      }
    }
  };
  changePasswordType = () => {
    const { inputType } = this.state;
    inputType === 'password'
      ? this.setState({ inputType: 'text' })
      : this.setState({ inputType: 'password' });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ inputValue: nextProps.value });
    }
  }
  render() {
    const {
      inputFilled,
      inputValue,
      inputType,
      showErrorMessage,
      inputErrorMessage,
    } = this.state;
    const { type, label, required, placeHolder, isError } = this.props;
    let inputClass = classNames({
      'form-element__input': true,
      'text-entered': inputFilled,
      'input-error': showErrorMessage,
    });
    return (
      <div className="form-element">
        <input
          type={inputType}
          className={inputClass}
          value={inputValue}
          onChange={this.onEnterInput}
          onBlur={this.onBlurInput}
          placeholder={placeHolder}
        />
        <span className="form-element__label">{label}</span>
        {(isError || (required && showErrorMessage)) && (
          <div className="error-message">{inputErrorMessage}</div>
        )}
      </div>
    );
  }
}

export default Input;
