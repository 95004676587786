import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLeaderBoard } from './actions';
import Table from './../../ui/table/Table';
import CardHolder from './../../ui/card/CardHolder';
import { sortBy, map } from 'lodash';
import { Animated } from 'react-animated-css';
import logo from './../../images/cops.png';
import wtLogo from './../../images/wtlogo.png';
class LeaderBoard extends Component {
  state = {
    firstItem: true,
    secondItem: false,
    //thirdItem: false,
    //fourthItem: false,
    firstScrollTime: 8000,
  };
  componentDidMount = () => {
    const { getLeaderBoard } = this.props;
    const { firstScrollTime } = this.state;
    this.interval = setInterval(() => {
      getLeaderBoard();
    }, 10000);
    setTimeout(() => this.spinItems(), firstScrollTime);
    getLeaderBoard();
  };
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.spinInterval);
  }
  setTimer = (timer) => {
    setTimeout(() => this.spinItems(), timer);
  };
  spinItems = () => {
    const { shamble_leader_board, stroke_leader_board } = this.props;
    //const { firstItem, secondItem, thirdItem, firstScrollTime } = this.state;
   const { firstItem, secondItem, firstScrollTime } = this.state;
	  const rotationSpeed = 1500;
    let shambleRandomNumber =
      shamble_leader_board && shamble_leader_board.length - 4 > 3 ? 3 : 0;
    let strokeRandomNumber =
      shamble_leader_board && stroke_leader_board.length - 4 > 3 ? 3 : 0;
    if (firstItem === true) {
      this.setState({ firstItem: false, secondItem: true });
      this.setTimer(
        (shamble_leader_board &&
          shamble_leader_board.length - shambleRandomNumber - 4) *
          rotationSpeed,
      );
    //} else if (secondItem === true) {
	} else {
      this.setState({ secondItem: false, firstItem: true });
      this.setTimer(
        (shamble_leader_board &&
          shamble_leader_board.length - shambleRandomNumber - 4) *
          rotationSpeed,
      );
    } //else if (thirdItem === true) {
     // this.setState({ thirdItem: false, fourthItem: true });
     // this.setTimer(
       // (stroke_leader_board &&
         // stroke_leader_board.length - strokeRandomNumber - 4) * rotationSpeed,
     // );
   // } else {
     // this.setState({ fourthItem: false, firstItem: true });
     // this.setTimer(firstScrollTime);
   // }
  };
  render() {
    const { shamble_leader_board, stroke_leader_board } = this.props;
    console.log(process.env.NODE_ENV);
//    const { firstItem, secondItem, thirdItem, fourthItem } = this.state;
      const { firstItem, secondItem } = this.state;
	  let shambleLeaderBoardUpdated = [],
      strokePlayLeaderBoardUpdated = [];
    map(shamble_leader_board, (board) => {
      let obj = {
        ...board,
        userPositionGross:
          typeof board.gross_user_position === 'string'
            ? parseInt(board.gross_user_position.substring(1))
            : board.gross_user_position,

        userPositionNet:
          typeof board.user_position === 'string'
            ? parseInt(board.user_position.substring(1))
            : board.user_position,
      };
      shambleLeaderBoardUpdated.push(obj);
    });
    map(stroke_leader_board, (board) => {
      let obj = {
        ...board,
        userPositionNet:
          typeof board.user_position === 'string'
            ? parseInt(board.user_position.substring(1))
            : board.user_position,
      };
      strokePlayLeaderBoardUpdated.push(obj);
    });
    return (
      <div className="wrapper">
        {/* first
         */}
        {firstItem && (
          <Animated
            animationIn="bounceInLeft"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={firstItem}
            className={'first-item'}
          >
            <div className="home">
              <div
                className="left-logo-image"
                style={{
                  backgroundImage: `url("${logo}")`,
                }}
              ></div>
              <span className="home-heading">Country Club Adjacent</span>
              <div
                className="right-logo-image"
                style={{
                  backgroundImage: `url("${wtLogo}")`,
                }}
              ></div>
            </div>
            <CardHolder
              leaderBoard={sortBy(
                shambleLeaderBoardUpdated,
                'userPositionGross',
              ).slice(0, 4)}
              scoreKey={'total_gross_score'}
              parKey={'total_gross_par_score'}
              positionKey={'gross_user_position'}
            />
        {/*    <div className="home">
              <span className="home-heading"> Leaders</span>
            </div>
            <CardHolder
              leaderBoard={sortBy(
                shambleLeaderBoardUpdated,
                'userPositionNet',
              ).slice(0, 4)}
              scoreKey={'total_net_score'}
              parKey={'total_score'}
              positionKey={'user_position'}
            />
            <div className="home">
              <span className="home-heading"> Pro Leaders</span>
            </div>
            <CardHolder
              leaderBoard={sortBy(
                strokePlayLeaderBoardUpdated,
                'userPositionNet',
              ).slice(0, 4)}
              scoreKey={'total_net_score'}
              parKey={'total_score'}
              positionKey={'user_position'}
            /> */}
          </Animated>
        )}
        {/* second
         */}
        {secondItem && (
          <Animated
            animationIn="bounceInLeft"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={secondItem}
            className={'second-item'}
          >
            <div className="home">
              <span className="home-heading">Country Club Adjacent</span>
            </div>
            <div className="card-full-wrapper">
              <div
                className="left-logo-image-card"
                style={{
                  backgroundImage: `url("${logo}")`,
                }}
              ></div>
              <div className="card-container">
                <CardHolder
                  leaderBoard={sortBy(
                    shambleLeaderBoardUpdated,
                    'userPositionGross',
                  ).slice(0, 4)}
                  scoreKey={'total_gross_score'}
                  parKey={'total_gross_par_score'}
                  positionKey={'gross_user_position'}
                />
              </div>
              <div
                className="right-logo-image-card"
                style={{
                  backgroundImage: `url("${wtLogo}")`,
                }}
              ></div>
            </div>
            {shambleLeaderBoardUpdated &&
              shambleLeaderBoardUpdated.length > 4 && (
                <Table
                  leaderBoard={sortBy(
                    shambleLeaderBoardUpdated,
                    'userPositionGross',
                  ).slice(4, shambleLeaderBoardUpdated.length)}
                  scoreKey={'total_gross_score'}
                  parKey={'total_gross_par_score'}
                  positionKey={'gross_user_position'}
                />
              )}
          </Animated>
        )}
        {/* third
         */}
        {/* {thirdItem && (
          <Animated
            animationIn="bounceInLeft"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={thirdItem}
            className={'second-item'}
          >
            <div className="home">
              <span className="home-heading">Net Leaders</span>
            </div>
            <div className="card-full-wrapper">
              <div
                className="left-logo-image-card"
                style={{
                  backgroundImage: `url("${logo}")`,
                }}
              ></div>
              <div className="card-container">
                <CardHolder
                  leaderBoard={sortBy(
                    shambleLeaderBoardUpdated,
                    'userPositionNet',
                  ).slice(0, 4)}
                  scoreKey={'total_net_score'}
                  parKey={'total_score'}
                  positionKey={'user_position'}
                />
              </div>
              <div
                className="right-logo-image-card"
                style={{
                  backgroundImage: `url("${wtLogo}")`,
                }}
              ></div>
            </div>
            {shambleLeaderBoardUpdated &&
              shambleLeaderBoardUpdated.length > 4 && (
                <Table
                  leaderBoard={sortBy(
                    shambleLeaderBoardUpdated,
                    'userPositionNet',
                  ).slice(4, shambleLeaderBoardUpdated.length)}
                  scoreKey={'total_net_score'}
                  parKey={'total_score'}
                  positionKey={'user_position'}
                />
              )}
          </Animated>
        )} */}
        {/* fourth
         */}
      {/*  {fourthItem && (
          <Animated
            animationIn="bounceInLeft"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={fourthItem}
            className={'third-item'}
          >
            <div className="home">
              <span className="home-heading">Pro Leaders</span>
            </div>
            <div className="card-full-wrapper">
              <div
                className="left-logo-image-card"
                style={{
                  backgroundImage: `url("${logo}")`,
                }}
              ></div>
              <div className="card-container">
                <CardHolder
                  leaderBoard={sortBy(
                    strokePlayLeaderBoardUpdated,
                    'userPositionNet',
                  ).slice(0, 4)}
                  scoreKey={'total_net_score'}
                  parKey={'total_score'}
                  positionKey={'user_position'}
                />
              </div>
              <div
                className="right-logo-image-card"
                style={{
                  backgroundImage: `url("${wtLogo}")`,
                }}
              ></div>
            </div>
            {strokePlayLeaderBoardUpdated &&
              strokePlayLeaderBoardUpdated.length > 4 && (
                <Table
                  leaderBoard={sortBy(
                    strokePlayLeaderBoardUpdated,
                    'userPositionNet',
                  ).slice(4, strokePlayLeaderBoardUpdated.length)}
                  scoreKey={'total_net_score'}
                  parKey={'total_score'}
                  positionKey={'user_position'}
                />
              )}
          </Animated>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shamble_leader_board: state.leaderBoardReducer.shamble_leader_board,
    stroke_leader_board: state.leaderBoardReducer.stroke_leader_board,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLeaderBoard: () => {
      dispatch(getLeaderBoard());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoard);
