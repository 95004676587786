import React from 'react';
import { map } from 'lodash';
import Card from './Card';

function CardHolder(props) {
  const { leaderBoard, scoreKey, parKey, positionKey } = props;
  return (
    <React.Fragment>
      <div className="card-holder">
        {map(leaderBoard, (board, i) => {
          return (
            <Card
              {...board}
              scoreKey={scoreKey}
              parKey={parKey}
              positionKey={positionKey}
              key={i}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default CardHolder;
