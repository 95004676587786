import { types } from './types';

const initialState = {
  auth_token: '',
  eventCode: '',
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_USER_INFORMATION: {
      return {
        ...state,
        auth_token: action.objFromLocalStorage.auth_token,
        user: JSON.parse(action.objFromLocalStorage.user),
      };
    }
    case types.USER_LOGIN_REQUEST: {
      return {
        ...state,
      };
    }
    case types.USER_LOGIN_SUCCESS: {
      return {
        auth_token: action.data.auth_token,
      };
    }
    case types.USER_LOGIN_FAILURE: {
      return {
        ...state,
      };
    }

    case types.UPDATE_EVENT_CODE: {
      return {
        ...state,
        eventCode: action.data.eventCode,
      };
    }

    case types.USER_LOGOUT_SUCCESS: {
      return { ...state, user: {} };
    }
    default: {
      return state;
    }
  }
}
