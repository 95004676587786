import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, floor } from 'lodash';
import TableRow from './TableRow';

class Table extends Component {
  state = {
    topValue: 0,
    wrapperHeight: 0,
    itemsShown: 0,
    counter: 0,
  };
  componentDidMount = () => {
    const { leaderBoard } = this.props;
    const topSectionHeight = 360,
      rowHeight = 85;
    let windowHeight = this.getWindowDimensions().height;
    let itemsShown = floor((windowHeight - topSectionHeight) / rowHeight);
    let wrapperHeight = itemsShown * rowHeight;
    this.setState({ wrapperHeight, itemsShown });
    this.scrollInterval = setInterval(() => {
      this.scrollItems();
    }, 1500);
    if (leaderBoard && leaderBoard.length <= itemsShown) {
      clearInterval(this.scrollInterval);
    }
  };
  componentDidUpdate = () => {
    const { leaderBoard } = this.props;
    const { itemsShown } = this.state;
    if (leaderBoard && leaderBoard.length <= itemsShown) {
      clearInterval(this.scrollInterval);
    }
  };
  componentWillUnmount = () => {
    clearInterval(this.scrollInterval);
  };
  scrollItems = () => {
    const { topValue, itemsShown, counter } = this.state;
    const { leaderBoard } = this.props;
    if (leaderBoard.length > itemsShown) {
      this.setState({
        topValue: (topValue * -1 + 85) * -1,
        counter: counter + 1,
      });
    }
    if (leaderBoard.length - counter - 1 === itemsShown) {
      clearInterval(this.scrollInterval);
    }
  };
  getWindowDimensions = () => {
    const { innerHeight: height } = window;
    return {
      height,
    };
  };
  render() {
    const { leaderBoard, scoreKey, parKey, positionKey } = this.props;
    const { topValue, wrapperHeight } = this.state;

    return (
      <React.Fragment>
        <div className="header">
          <div className="cell" />
          <div className="cell" />
          <div className="cell">Thru</div>
          {/* <div className="cell">Score</div> */}
          <div className="cell">To par</div>
        </div>
        <div className="table-wrapper" style={{ height: `${wrapperHeight}px` }}>
          <div
            className="table"
            style={{
              transform: `translate(${0}px, ${topValue}px)`,
            }}
          >
            {map(leaderBoard, (board, i) => {
              return (
                <TableRow
                  {...board}
                  scoreKey={scoreKey}
                  parKey={parKey}
                  positionKey={positionKey}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
