import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isEmpty, some } from 'lodash';
import { authenticateUser, updateURL } from './actions';
import Input from '../../ui/input';

class LoginContainer extends Component {
  state = {
    email: 'p5avsu8@wt.com',
    password: 'qazplm123',
    emailError: false,
    passwordError: false,
    isMounted: false,
  };
  componentDidMount = () => {
    const { user, history } = this.props;
    this.setState({ isMounted: true });
    if (some(user, isEmpty) && localStorage.getItem('user')) {
      updateURL(history);
    }
  };
  componentWillUnmount = () => {
    this.setState({ isMounted: false });
  };
  getEmailAddress = (email) => {
    this.setState({ email, emailError: false });
  };
  getPassword = (password) => {
    this.setState({ password, passwordError: false });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const { authenticateUser, history } = this.props;
    if (!email && !password) {
      this.setState({ emailError: true, passwordError: true });
      return;
    }
    if (!email) {
      this.setState({ emailError: true });
      return;
    }
    if (!password) {
      this.setState({ passwordError: true });
      return;
    }
    this.setState({
      emailError: false,
      passwordError: false,
    });
    let data = { user: { email, password } };
    authenticateUser(data, history);
  };
  render() {
    const { isFetching } = this.props;
    const { emailError, passwordError } = this.state;
    let btnClass = classNames({
      btn: true,
      'btn-big': true,
      'btn-default': true,
      'btn-lite': true,
      'btn-disabled': isFetching,
    });
    let place = isFetching ? 'Signing in...' : 'Login';
    return (
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-wrapper__right">
            <div className="login-holder">
              <div className="login-holder__header">
                <div className="login-form-holder">
                  <div className="login-form">
                    <div className="login-form-header">
                      <div className="login-form-header__heading">Login</div>
                    </div>
                    <div className="login-form-body">
                      <form onSubmit={this.onSubmit}>
                        <Input
                          type={'email'}
                          label={'Email Address'}
                          getValue={this.getEmailAddress}
                          required={true}
                          isError={emailError}
                          errorMessage={'Enter a valid email!'}
                          blankError={'Please enter your email'}
                        />
                        <Input
                          type={'password'}
                          label={'Password'}
                          getValue={this.getPassword}
                          required={true}
                          isError={passwordError}
                          errorMessage={'Please match with the hint given'}
                          blankError={'Please enter your password'}
                        />
                        <div className="form-element">
                          <input
                            className={btnClass}
                            type="submit"
                            onClick={this.onSubmit}
                            value={place}
                            style={{ border: 'none' }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    isFetching: state.authReducer.isFetching,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    authenticateUser: (data, history) => {
      console.log(data);
      dispatch(authenticateUser(data, history));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
