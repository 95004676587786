import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { updateEventCode } from './actions';
import Input from '../../ui/input';

class EventCodeDetails extends Component {
  state = {
    eventCode: '',
    eventCodeError: false,
  };

  getEventCode = (eventCode) => {
    this.setState({ eventCode, eventCodeError: false });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const { eventCode } = this.state;
    const { updateEventCode, history } = this.props;
    if (!eventCode) {
      this.setState({ eventCodeError: true });
      return;
    }
    this.setState({
      eventCodeError: false,
    });
    let data = { eventCode };
    updateEventCode(data, history);
  };
  render() {
    const { isFetching } = this.props;
    const { eventCodeError } = this.state;
    let btnClass = classNames({
      btn: true,
      'btn-big': true,
      'btn-default': true,
      'btn-lite': true,
      'btn-disabled': isFetching,
    });
    let place = isFetching ? 'Entering...' : 'Enter';
    return (
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-wrapper__right">
            <div className="login-holder">
              <div className="login-holder__header">
                <div className="login-form-holder">
                  <div className="login-form">
                    <div className="login-form-header">
                      <div className="login-form-header__heading">
                        Enter Event Code
                      </div>
                    </div>
                    <div className="login-form-body">
                      <form onSubmit={this.onSubmit}>
                        <Input
                          type={'text'}
                          label={'Event Code'}
                          getValue={this.getEventCode}
                          required={true}
                          isError={eventCodeError}
                          errorMessage={'Enter a valid event code'}
                          blankError={'Please enter event code'}
                        />
                        <div className="form-element">
                          <input
                            className={btnClass}
                            type="submit"
                            onClick={this.onSubmit}
                            value={place}
                            style={{ border: 'none' }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateEventCode: (data, history) => {
      console.log(data);
      dispatch(updateEventCode(data, history));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventCodeDetails);
